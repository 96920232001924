/*
 * @Description: AKJERRT
 * @Date: 2022-07-14 11:59:56
 * @LastEditTime: 2022-07-14 14:12:31
 * @FilePath: \linkgap-front\src\views\account_statement\components\mixin\insert.js
 */

export const mixin = {
  data() {
    return {
      provinceData: ['相符', '不相符'],
      searchData: {}, // 搜索条件
      dealerList: [],
      regionalOffice: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
    getToken() {
      return this.$sotre.state.SET_TOKEN()
    },
  },
  created() {
    this.getData()
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
        // 获取大区事务所经销商数据
        this.dealerList = res.body
      })
    },
    // 大区事务所
    onChange(val) {
      this.searchData.areaId = val[0]
      this.searchData.firmId = val[1]
    },
    // 查询
    query() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
     * 重置
     */
    reset() {
      this.searchData = {}
      this.regionalOffice = []
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },
    /**
   * 更改分页
   */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },
    setQKStyle(record, index) {
      return {
        style: {
          'background-color': 'red',
        },
      }
    },
  }
}